<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcAnnualMembershipDinnerSpeakers }}</h1>
    </header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <data-table :fields="fields" :listType="list_type" :i18n="translations.components" :items="dinnerSpeakersAlter"
      :toggleMe="true" :addItems="addItems" :handleDeleteAction="allow_delete_action"
      :handleEditAction="allow_delete_action" :handleViewAction="false" :csvUrl="dinnerSpeakersCSVUrl" :pdfUrl="pdfUrl"
      :reportName="reportName" @handleEditClick="handleEditClick" @handleDeleteClick="handleDeleteClick"
      @addPresenter="addPresenter"></data-table>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'membership-dinner-speakers',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        membership_dinner_speaker_add_edit_delete_button: '4b6f109d-45e2-4ecd-9832-ad2c79378110',
      },
      allow_delete_action: false,
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'org_name', label: 'Camp', sortable: true },
        { key: 'adr_city', label: 'City', sortable: true },
        { key: 'adr_state', label: 'State', sortable: true },
        {
          key: 'qualified_date',
          label: 'Date Qualified',
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        { key: 'actions', sortable: false, tdClass: 'cwidth' },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'AnnualMembershipDinnerSpeakersReport',
    }
  },
  methods: {
    ...mapActions({
      getDinnerSpeakers: 'directory/getDinnerSpeakers',
      getMemberChurches: 'campModule/getMemberChurches',
      loadChurches: 'churchMinistry/loadChurches',
      loadDinnerSpeakersUrls: 'directory/loadDinnerSpeakersUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSpeakerObject: 'directory/setSpeakerObject',
      upsertDinnerSpeakers: 'directory/upsertDinnerSpeakers',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getDinnerSpeakers(this.officerSelectStatekey.country_state)
        await this.loadDinnerSpeakersUrls(this.officerSelectStatekey.country_state)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addPresenter() {
      await this.setSpeakerObject({ item: {}, value: '' })
      this.$router.push({
        name: this.isState ? 'membership-dinner-speakers-upsert' : 'membership-dinner-speakers-upsert-country',
      })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddMember
      this.addItems.display = this.iCanSee(this.secured_controls.membership_dinner_speaker_add_edit_delete_button)
      this.allow_delete_action = this.iCanSee(this.secured_controls.membership_dinner_speaker_add_edit_delete_button)
      const { tcActions, tcCamp, tcCity, tcDateQualified, tcName, tcState } = this.translations
      this.fields[0].label = tcName
      this.fields[1].label = tcCamp
      this.fields[2].label = tcCity
      this.fields[3].label = tcState
      this.fields[4].label = tcDateQualified
      this.fields[5].label = tcActions
    },
    async handleEditClick(data) {
      await this.setSpeakerObject(data)
      this.$router.push({
        name: this.isState ? 'membership-dinner-speakers-upsert' : 'membership-dinner-speakers-upsert-country',
      })
    },
    handleDeleteClick(data) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
        text: this.translations.tcOkToDeleteSpeaker,
      }).then((result) => {
        if (result.value) {
          this.handleDeleteRequest(data)
        }
      })
    },
    async handleDeleteRequest(data) {
      let requestData = {
        ixo_key: data.item.ixo_key,
        ixa_key: data.item.ixa_key,
        acr_ind_key: data.item.vin_ind_key,
        org_key: this.officerSelectStatekey.country_state,
        delete: true,
        user_ind_key: this.userId,
        start_date: data.item.qualified_date,
      }
      const response = await this.upsertDinnerSpeakers(requestData)
      if (response) {
        await this.page_load()
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      dinnerSpeakersCSVUrl: 'directory/dinnerSpeakersCSVUrl',
      dinnerSpeakers: 'directory/dinnerSpeakers',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSee: 'user/iCanSee',
      officerSelectStatekey: 'user/officerToolbarSelected',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
      userOrgKey: 'user/userOrgKey',
      userSelectedCampType: 'user/userSelectedCampType',
      userStateKey: 'user/userStateKey',
    }),
    dinnerSpeakersAlter() {
      let dinnerSpeakers = this.dinnerSpeakers.map((x) => {
        return {
          ixa_key: x.ixa_key,
          vin_ind_key: x.ind_key,
          vin_complete_name: x.vin_complete_name,
          sort_name: x.sort_name,
          org_name: x.org_name,
          adr_city: x.adr_city,
          adr_state: x.adr_state,
          ixo_key: x.ixo_key,
          qualified_date: x.qualified_date,
        }
      })
      return dinnerSpeakers
    },
    isState() {
      return this.$route.path.toLowerCase().includes('country') ? false : true
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
